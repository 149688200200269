import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import './EventCalendar.css';
import { getData } from '../../services/quickbase';
import { Button, Modal } from 'react-bootstrap';
import EventModal from './EventModal';

const reportId = '9';
const tableId = 'brd3cczs8';
const reportName = 'Calendar';

// https://istasolutions.quickbase.com/db/brd7ic6n2?a=td

function EventCalendar() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [eventData, setEventData] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", 'QB-TICKET ' + localStorage.getItem("qb_user_auth_ticket"));
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("QB-Realm-Hostname", "istasolutions.quickbase.com");

    var raw = JSON.stringify({
      "from": "brd3cczs8"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.quickbase.com/v1/records/query", requestOptions)
      .then(response => response.text())
      .then(resp => JSON.parse(resp)['data'])
      .then(resp => {

        var r = resp.map(x => {
          x['date'] = x[8].value + ' ' + x[9].value;
          x['title'] = x[12].value;
          var names = [];
          var functions = [];
          x[19].value.forEach(element => {
            names.push(element["name"]);
          });
          x['ista_people'] = names.join(',');

          // x[23].value.forEach(element => {
          //   functions.push(element);
          // });
          // x['function'] = functions.join(',');
          
          return x;
        });

        setData(r)
      }).catch(error => console.log('error', error));
  }, []);

  const columns = [
    {
      name: 'Client Name',
      selector: 'Client_Name',
      sortable: true,
    },
    {
      name: 'Employee Name',
      selector: 'Employee_Name',
      sortable: true,
    },
    {
      name: 'Working Status',
      selector: 'Working_Status',
      sortable: true,
    },
  ];

  return (
    <div className="col-lg-11 col-xs-2  p-4 mt-3 mr-4" style={{ backgroundColor: 'white' }}>
      <Modal show={show} onHide={handleClose} className="cust_mod" >
        <Modal.Body  className="cust_mod_body">
          <EventModal eventData={eventData} />
        </Modal.Body>
        
      </Modal>

      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        timeZone='America/New_York'
        events={data}
        eventClick={(info) => {

          setEventData(info.event._def);
          handleShow();
        }}
        headerToolbar={{
          start: 'title', // will normally be on the left. if RTL, will be on the right
          center: '',
          end: 'prev,next' // will normally be on the right. if RTL, will be on the left
        }}
        height="auto"
      />
    </div>
  )
}

export default EventCalendar
