import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { getData } from '../../services/quickbase';
import './MyTeam.css';
import AgentModal from './AgentModal';
import { Button, Modal } from 'react-bootstrap';

const reportId = '75';
const tableId = 'bp53ryam3';
const reportName = 'Schedules';

// https://istasolutions.quickbase.com/db/brd7ic59m?a=q&qid=75

let data = [];

function MyTeam() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [employeeData, setEmployeeData] = useState([]);


    const [data, setData] = useState([]);

    useEffect(() => {
        let response = getData(reportId, tableId, reportName);
        response.then((resp) => {
            console.log(resp);
            setData(resp);

        })
    }, [])
    // colors for working status are 
    // Absent   - #F80303
    // Present  - #08DD00
    // Break    - #FFA722
    // Other    - #E3FF22


    function renderWorkingStatus(Working_Status) {
        switch (Working_Status) {
            case 'Absent':
                return '#F80303'
            case 'Present':
                return '#08DD00'
            case 'Break':
                return '#FFA722'
            default:
                return '#E3FF22'
        }
    }

    const columns = [
        {
            name: 'AGENT',
            selector: 'Employee_Name',
            sortable: true,
            cell: row => <>


                {row["Employee_-_Picture_URL2"] !== ""
                    ? <div style={{ display: 'flex', marginBottom: '2px', marginTop: '2px' }}><img src={row["Employee_-_Picture_URL2"]} height="50px" width="50px" className="rounded-circle mr-3" alt="emp" /> {row.Employee_Name}</div> : <div>{row.Employee_Name}</div>}


            </>
            // row.View_Picture.match(/([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/)
        },
        {
            name: 'FUNCTION',
            selector: 'Function',
            sortable: true,
            cell: row => <>{row['assignment_-_Function2']}<br />&#x1F6C8;</>
        },
        {
            name: 'STATUS',
            selector: 'Working_Status',
            sortable: true,
            left: true,
            cell: row => <div style={{ width: '100%' }}><div style={{ fontWeight: 700, display: 'flex', alignContent: 'center', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>{row.Working_Status}<div style={{ backgroundColor: renderWorkingStatus(row.Working_Status), width: '1em', height: '4em', display: 'inline-block', marginRight: 10, marginTop: 5, marginBottom: 5 }}>&nbsp;</div></div></div>,
        },
    ];

    const handleChange = (state) => {
        console.log('Selected Rows: ', state.selectedRows);
    };

    return (
        <div className="col-lg-6 col-xs-2 p-4 mt-3 mr-4" style={{ backgroundColor: 'white' }}>
            <Modal show={show} onHide={handleClose} className="agent-modal">
                <Modal.Body className="agent-modal-body">
                    <AgentModal employeeData={employeeData} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* <div className="col-md-11 p-4"> */}
            <DataTable
                title={<h4 style={{ fontFamily: 'Lexend', fontWeight: 'bold', color: '#282587' }}>My Team</h4>}
                className={"rdt_Table"}
                columns={columns}
                data={data}
                pagination={true}
                paginationPerPage={10}
                responsive={true}
                dense={true}
                onRowClicked={(row) => {
                    setEmployeeData(row);
                    handleShow();
                }}

            />

            {/* <div className="table-responsive" style={{ fontFamily: 'Lexend' }}>
                <table className="table table-bordered">
                    <thead style={{ fontWeight: 'bold', backgroundColor: '#282587', color: '#ffffff' }}>
                        <tr>
                            <th style={{ fontFamily: 'Lexend', fontWeight: 'bold' }}>AGENT</th>
                            <th>FUNCTION</th>
                            <th>STATUS</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><img src="assets/img/Rectangle%2013.png" alt='emp1' />&nbsp;John Smith<br /></td>
                            <td>Function is here</td>
                            <td className="text-left">Absent&nbsp;</td>
                            <td><img src="assets/img/Rectangle%2061.png" alt='emp1' /></td>
                        </tr>
                        <tr>
                            <td><img src="assets/img/Rectangle%2013.png" alt='emp1' />&nbsp;John Smith</td>
                            <td />
                            <td>Present&nbsp;</td>
                            <td><img src="assets/img/Rectangle%2062.png" alt='emp1' /></td>
                        </tr>
                        <tr>
                            <td><img src="assets/img/Rectangle%2013.png" alt='emp1' />&nbsp;John Smith</td>
                            <td />
                            <td>Break&nbsp;</td>
                            <td><img src="assets/img/Rectangle%2063.png" alt='emp1' /></td>
                        </tr>
                        <tr>
                            <td><img src="assets/img/Rectangle%2013.png" alt='emp1' />&nbsp;John Smith</td>
                            <td />
                            <td>Break&nbsp;</td>
                            <td><img src="assets/img/Rectangle%2063.png" alt='emp1' /></td>
                        </tr>
                        <tr>
                            <td><img src="assets/img/Rectangle%2013.png" alt='emp1' />&nbsp;John Smith</td>
                            <td />
                            <td>Break&nbsp;</td>
                            <td><img src="assets/img/Rectangle%2063.png" alt='emp1' /></td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
            {/* </div> */}
        </div>
    )
}

export default MyTeam
