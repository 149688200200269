import React, { useEffect, useState } from 'react'
import { WeatherWidget } from '../WeatherWidget/WeatherWidget'
import Clock from 'react-live-clock';
import { getData } from '../../services/quickbase';
import './Header.css';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import axios from 'axios';

const reportId = null;
const tableId = 'bp4a2nmzu';
const reportName = null;
const appId = 'bp4ahbe46';
const apiKey = '51f5995ffb3f12e930111c6ad279fcfa';

function Header() {

    const [lat, setLat] = useState([]);
    const [long, setLong] = useState([]);
    const [icon, setIcon] = useState([]);
    const [temp, setTemp] = useState([]);
    const [desc, setDesc] = useState([]);

    const [iconmakati, setIconmakati] = useState([]);
    const [tempmakati, setTempmakati] = useState([]);
    const [descmakati, setDescmakati] = useState([]);
    

    useEffect(() => {
        const fetchData = async () => {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLat(position.coords.latitude);
                setLong(position.coords.longitude);
                // console.log('latitude', position.coords.latitude);
                // console.log('longitude', position.coords.longitude);


                var config = {
                    method: 'get',
                    url: 'https://api.openweathermap.org/data/2.5/weather?lat='+position.coords.latitude+'&lon='+position.coords.longitude+'&appid=51f5995ffb3f12e930111c6ad279fcfa&units=imperial',
                    headers: {}
                };

                axios(config)
                    .then(function (response) {
                        
                        setTemp(response.data['main']['temp']);
                        setDesc(response.data['weather'][0]['description']);
                        setIcon('http://openweathermap.org/img/wn/' + response.data['weather'][0]['icon'] + '@2x.png');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                    var config = {
                        method: 'get',
                        url: 'https://api.openweathermap.org/data/2.5/weather?lat=14.5547&lon=121.0244&appid=51f5995ffb3f12e930111c6ad279fcfa&units=imperial',
                        headers: {}
                    };
    
                    axios(config)
                        .then(function (response) {
                            
                            setTempmakati(response.data['main']['temp']);
                            setDescmakati(response.data['weather'][0]['description']);
                            setIconmakati('http://openweathermap.org/img/wn/' + response.data['weather'][0]['icon'] + '@2x.png');
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
    

            });
        }
        fetchData();
    }, [lat, long])


    // http://openweathermap.org/img/wn/10d@2x.png


    const customStyles = {
        fontFamily: 'Lexend',
        gradientStart: '#ffffff00',
        gradientMid: '#ffffff00',
        gradientEnd: '#ffffff00',
        locationFontColor: '#FFF',
        todayTempFontColor: '#1F1C66',
        todayDateFontColor: '#1F1C66',
        todayRangeFontColor: '#1F1C66',
        todayDescFontColor: '#1F1C66',
        todayInfoFontColor: '#1F1C66',
        todayIconColor: '#1F1C66',
    };

    const [client, setClient] = useState([]);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", 'QB-TICKET ' + localStorage.getItem("qb_user_auth_ticket"));
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("QB-Realm-Hostname", "istasolutions.quickbase.com");

        var raw = JSON.stringify({
            "from": "bp4a2nmzu",
            "select": [
                6
            ]
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.quickbase.com/v1/records/query", requestOptions)
            .then(response => response.text())
            .then(result =>
                setClient(JSON.parse(result)['data'][0]['6']['value'])
            )
            .catch(error => console.log('error', error));
    }, [])


    return (<>
        <div className="row">
            <div className="col-lg-2 ml-5 col-sm-12 d-flex justify-content-center align-items-center"><img className="img-responsive" alt="ista solutions" src="https://istasolutions.com/wp-content/uploads/2018/05/logo-250-min.png" /></div>
            <div className="col-lg-1" />
            <div className="col-lg-6 col-sm-12">
                <div className="row text-center">
                    <div className="col-lg-4 border-right d-flex align-items-center justify-content-center flex-column">
                        <h5 className="text-center" style={{ fontFamily: 'Lexend', fontWeight: 'bold' }}>MAKATI TIME:</h5>
                        <h2 className="text-center" style={{ marginTop: '10px', fontFamily: 'Lexend', fontWeight: 'bold' }}><Clock format={'hh:mm A'} ticking={true} timezone={'Asia/Manila'} /></h2>
                    </div>
                    <div className="col-lg-6 text-left ml-2">
                        <h4 style={{ fontFamily: 'Lexend', fontWeight: 'bold' }}>WEATHER</h4>
                        {/* <WeatherWidget city_name={"LOCAL"} basecolor="white" link={"https://forecast7.com/en/14d55121d02/makati/"} /> */}
                        <div style={{
                            textShadow: 'none', fontFamily: 'Tahoma,Arial,Verdana,Segoe,sans-serif', fontSize: '2em',
                            color: '#434343'
                        }}><img src={iconmakati} alt="weather" />{Math.round(tempmakati)}°F</div>
                        <p>{descmakati}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" style={{ backgroundColor: '#F1F1F1' }}>
            <div className="col-lg-2 col-sm-12 ml-5 d-flex justify-content-center align-items-center">
                <h1 style={{ fontFamily: 'Lexend', fontWeight: 'bold' }}>{client}</h1>
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-6">
                <div className="row text-center">
                    <div className="col-lg-4 border-right d-flex align-items-center justify-content-center flex-column">
                        <h5 className="text-center" style={{ fontFamily: 'Lexend', fontWeight: 'bold' }}>LOCAL TIME:</h5>
                        <h2 className="text-center" style={{ marginTop: '10px', fontFamily: 'Lexend', fontWeight: 'bold' }}><Clock format={'hh:mm A'} ticking={true} timezone={Intl.DateTimeFormat().resolvedOptions().timeZone} /></h2>
                    </div>
                    <div className="col-lg-6 text-left ml-2">
                        <h4 style={{ fontFamily: 'Lexend', fontWeight: 'bold' }}>WEATHER</h4>
                        {/* <WeatherWidget city_name={"LOCAL"} basecolor="#F1F1F1" link={"https://forecast7.com/en/40d71n74d01/new-york/"} /> */}
                        <div style={{
                            textShadow: 'none', fontFamily: 'Tahoma,Arial,Verdana,Segoe,sans-serif', fontSize: '2em',
                            color: '#434343'
                        }}><img src={icon} alt="weather" />{Math.round(temp)}°F</div>
                        <p>{desc}</p>
                        {/* <ReactWeather
                            theme={customStyles}
                            isLoading={isLoading}
                            errorMessage={errorMessage}
                            data={data}
                            lang="en"
                            unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                            showForecast={false}
                        /> */}
                    </div>

                </div>
            </div>
            <div className="col-lg-5" />

        </div></>
    )
}

export default Header
