import React, { useEffect, useState } from 'react'
import { getData } from '../../services/quickbase';
import './Sticky.css'

const reportId = '';
const tableId = 'bp4a2nmzu';
const reportName = 'Sticky Note';

function Sticky() {

    // https://istasolutions.quickbase.com/db/brd7ic57w?a=td
    // Announcement Column

    const [announcement, setAnnouncement] = useState([]);

    useEffect(() => {
        var axios = require('axios');
        var data = JSON.stringify({
            "from": "bp4a2nmzu",
            "select": [
                281
            ]
        });
        var config = {
            method: 'post',
            url: 'https://api.quickbase.com/v1/records/query',
            headers: {
                'QB-Realm-Hostname': 'istasolutions.quickbase.com',
                'Authorization': 'QB-TICKET ' + localStorage.getItem("qb_user_auth_ticket"),
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setAnnouncement(response.data['data'][0]['281']['value']);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])

    return (
        <div>
            <img src="https://i.ibb.co/Q6MGV96/sticky.png" alt="sticky" height="500" align="left" id="sticky" className="hidden-lg-down" />
            <h1 id="stickynote">{announcement}</h1>
        </div>
    )
}

export default Sticky
