import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { getData } from '../../services/quickbase';

const reportId = '10';
const tableId = 'bqhdx99vh';
const reportName = 'End of the day report';

let data = [];
function EODReport() {
    const [data, setData] = useState([]);

    useEffect(() => {
        let response = getData(reportId, tableId, reportName);
        response.then((resp) => {
            setData(resp);
            console.log('eod resp',resp)
        })
    }, [])

    const Export = ({ onExport }) => (
        <Button variant="light" onClick={e => onExport(e.target.value)}>📄</Button>
    );

    const columns = [
        {
            name: 'DATE',
            selector: 'Date',
            sortable: true,
            cell: row => <><div><a href={row.Link_To_Item_for_Dashboard} target="_blank" rel="noreferrer">{row.Date}</a></div></>
        }
    ];

    // https://istasolutions.quickbase.com/db/brd7ic6ic?a=q&qid=10
    // End of Day Report    >	Previous End Of Days

    return (
        <div className="col-lg-5 col-xs-2  p-4 mt-3 mr-4" style={{ backgroundColor: 'white' }}>
            {/* <div className="col-md-11 p-5" style={{ backgroundColor: 'white' }}> */}
                <DataTable
                    title={<h4 style={{ fontFamily: 'Lexend', fontWeight: 'bold', color: '#282587' }}>End of Day Production Report</h4>}
                    columns={columns}
                    data={data}
                    pagination={true}
                    paginationPerPage={10}
                    responsive={true}
                    pointerOnHover={true}
                />

                {/* <div className="table-responsive" style={{ fontFamily: 'Lexend' }}>
                    <table className="table table-bordered">
                        <thead style={{ fontWeight: 'bold', backgroundColor: '#282587', color: '#ffffff' }}>
                            <tr>
                                <th style={{ fontFamily: 'Lexend', fontWeight: 'bold' }}>DATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>05.14.2021</td>
                            </tr>
                            <tr />
                            <tr>
                                <td>05.14.2021</td>
                            </tr>
                            <tr>
                                <td>05.14.2021</td>
                            </tr>
                            <tr>
                                <td>05.14.2021</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
            {/* </div> */}
        </div>
    )
}

export default EODReport
