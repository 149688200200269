import React from 'react'

// The record is coming of clicked employee from MyTeam same Report in Quickbase

function AgentModal(props) {

    function convertDate(date) {
        try {
            const firstValentineOfTheDecade = new Date(date);
            const enUSFormatter = new Intl.DateTimeFormat('en-US');
            return (enUSFormatter.format(firstValentineOfTheDecade));
        } catch (error) {
            return 0;
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-5 text-center  d-flex align-items-center">
                { props.employeeData["Employee_-_Picture_URL2"] !== ""
                        ?
                    <img className="rounded-circle mr-3" src={props.employeeData["Employee_-_Picture_URL2"]} alt="employee_profile" style={{ width: '144px', height: '144px' }} />
                    : props.employeeData["Employee_-_Picture_URL2"]}
                    </div>
                <div className="col-md-6  d-flex align-items-center">
                    <h1 style={{ fontFamily: 'Lexend', fontWeight: 'bold', color: '#282587' }}>{props.employeeData.Employee_Name}</h1>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-5 text-center  d-flex align-items-center">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ fontFamily: 'Lexend', backgroundColor: '#282587', color: 'rgb(255,255,255)', overflow: 'hidden' }} className="d-flex align-items-center">
                                    <h6 style={{ fontWeight: 'bold' }}>JOB TITLE</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr />
                            <tr />
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6  d-flex align-items-center">
                    <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: '#282587' }}>{props.employeeData["assignment_-_Position"]}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 text-center  d-flex align-items-center">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ fontFamily: 'Lexend', backgroundColor: '#282587', color: 'rgb(255,255,255)', overflow: 'hidden' }} className="d-flex align-items-center">
                                    <h6 style={{ fontWeight: 'bold' }}>START DATE</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr />
                            <tr />
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6  d-flex align-items-center">
                    <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: '#282587' }}>{convertDate(props.employeeData["Employee_-_Start_Date"])}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 text-center  d-flex align-items-center">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ fontFamily: 'Lexend', backgroundColor: '#282587', color: 'rgb(255,255,255)', overflow: 'hidden' }} className="d-flex align-items-center">
                                    <h6 style={{ fontWeight: 'bold', display: 'inline' }}>BIRTHDAY</h6><img src="https://i.ibb.co/By7qpHK/birthday-cake-icon-01.png" alt="birthday" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr />
                            <tr />
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6  d-flex align-items-center">
                    <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: '#282587' }}>{convertDate(props.employeeData["Employee_-_DOB"])}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 text-center  d-flex align-items-center">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ fontFamily: 'Lexend', backgroundColor: '#282587', color: 'rgb(255,255,255)', overflow: 'hidden' }} className="d-flex align-items-center">
                                    <h6 style={{ fontWeight: 'bold' }}>EMAIL</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr />
                            <tr />
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6  d-flex align-items-center">
                    <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: '#282587' }}>{props.employeeData["Employee_-_Company_Email_Address"]}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 text-center  d-flex align-items-center">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ fontFamily: 'Lexend', backgroundColor: '#282587', color: 'rgb(255,255,255)', overflow: 'hidden' }} className="d-flex align-items-center">
                                    <h6 style={{ fontWeight: 'bold' }}>PHONE #</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr />
                            <tr />
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6  d-flex align-items-center">
                    <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: '#282587' }}>{props.employeeData["Employee_-_Company_Phone/_Extension"]}</h6>
                </div>
            </div>
        </div>
    )
}

export default AgentModal
