function getData(reportId, tableId, reportName, appId = '') {

  let headers = {
    'QB-Realm-Hostname': 'istasolutions.quickbase.com',
    'User-Agent': 'FileService_Integration_V2.1',
    'Authorization': 'QB-TICKET ' + localStorage.getItem("qb_user_auth_ticket"),
    'Content-Type': 'application/json'
  };

  let url = '';
  let fetchPromise = null;

  if (appId === '') {
    url = 'https://api.quickbase.com/v1/reports/' + reportId + '/run?tableId=' + tableId;
    fetchPromise = fetch(url, {
      method: 'POST',
      headers: headers,
    });

  }
  else {
    url = 'https://api.quickbase.com/v1/tables/' + tableId + '?appId=brd7ic56z';
    fetchPromise = fetch(url, {
      method: 'GET',
      headers: headers,
    });

  }

  // console.log(reportName + ' - ' + url);
  let tableArray = {};
  let finalTableArray = [];




  const fetchResult = fetchPromise.then(response => {
    return response.json();
  }).then(responseData => {
    console.log('report' + reportId, JSON.stringify(responseData));
    let count = 0;
    try {
      responseData["data"].map((item, index) => {
        const tableArray = {};
        const array = Object.entries(item);
        array.forEach(element => {
          const foundValue = responseData["fields"].filter(obj => obj.id === parseInt(element[0]));
          const label = foundValue[0].label.replace(/ /g, "_");
          const value = element[1]['value'];
          tableArray[label.toString()] = value;
          tableArray['id'] = count;
          count++;
        });

        // console.log('tableArray',tableArray);
        finalTableArray.push(tableArray);
        // return finalTableArray;
        // console.log('level1',finalTableArray);
      });
    } catch (error) {

    }

    return finalTableArray;
  }).then(response => {
    return response;
  })

  return fetchResult;
}

export {
  getData
}