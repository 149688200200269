import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header/Header';
import MyTeam from './components/MyTeam/MyTeam';
import EventCalendar from './components/EventCalendar/EventCalendar';
import Rewards from './components/Rewards/Rewards';
import EODReport from './components/EODReport/EODReport';
import Sticky from './components/Header/Sticky';
import { Button, Modal } from 'react-bootstrap';
import CommentModal from './CommentModal';
import Login from './components/Login/Login';

// send us a message
// https://istasolutions.quickbase.com/db/brd7ic6nq?a=td

function App() {

  const [geoLat, setGeoLat] = useState(0);
  const [geoLng, setGeoLng] = useState(0);
  const [geoAcc, setGeoAcc] = useState(0);
  const [url, setUrl] = useState(0);

  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const handleClose = () => setShow(false);
  const handleCloseLogin = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowLogin = () => setShow(true);

  useEffect(() => {
    var tokenExpire = localStorage.getItem('qb_user_auth_ticket_expire');
    if (tokenExpire < new Date().getTime()) {
      console.log("EXPIRED");
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
  })

  useEffect(() => {

    //More complete version

    // Request the current position
    // If successful, call getPosSuccess; On error, call getPosErr
    // navigator.geolocation.getCurrentPosition(getPosSuccess, getPosErr);

    // getCurrentPosition: Successful return
    function getPosSuccess(pos) {
      // Get the coordinates and accuracy properties from the returned object
      setGeoLat(pos.coords.latitude.toFixed(5));
      setGeoLng(pos.coords.longitude.toFixed(5));
      setGeoAcc(pos.coords.accuracy.toFixed(1));
      // setUrl(String.toString(geoLng).replace('.', 'd') + 'n' + String.toString(geoLat).replace('.', 'd'));
      setUrl(geoLat);
    }

    // getCurrentPosition: Error returned
    function getPosErr(err) {
      switch (err.code) {
        case err.PERMISSION_DENIED:
          alert("User denied the request for Geolocation.");
          break;
        case err.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case err.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        default:
          alert("An unknown error occurred.");
      }
    }

  }, [])

  return (
    <div className="d-lg-flex justify-content-lg-center">
      <div>
        <Modal show={showLogin} onHide={handleCloseLogin}>
          <Modal.Body>
            <Login show={showLogin} handleClose={handleCloseLogin} />
          </Modal.Body>
        </Modal>
        {showLogin === false && <>
          <Modal show={show} onHide={handleClose} className="send-modal">
            <Modal.Body className="send-modal-body">
              <CommentModal show={show} handleClose={handleClose} />
            </Modal.Body>
            
          </Modal>

          <br />
          <Sticky />
          <Header />
          <div style={{ backgroundColor: '#2C2892' }}>
            <br />
            <br />
            <button className="badge badge-pill badge-primary ml-5" target="_blank" rel="noreferrer" style={{
              backgroundColor: '#00AEEF',
              borderColor: '#00AEEF', color: '#ffffff', fontFamily: 'Lexend', fontWeight: 'bold', fontSize: '1.5em', paddingLeft: '2em', paddingRight: '2em'
            }} onClick={() => {
              handleShow();
            }}>Send Us <br />a Message</button><br />
            <div className="row" className="d-lg-flex justify-content-lg-center">
              <MyTeam />
              <EODReport />
            </div>
            <br />
            {/* <div className="row" className="d-lg-flex justify-content-lg-center">
            <Rewards />
            
            
          </div> */}
            <div className="row" className="d-lg-flex justify-content-lg-center">

              <EventCalendar />

            </div>

            <br /><br /><br /><br />
          </div>
        </>}
      </div>
    </div>
  );
}

export default App;
