import React from 'react'

function EventModal(props) {

    // console.log('eventmodal', props);

    function eventBackground() {
        var eventTitle = props.eventData.title;
        switch (eventTitle) {
            case 'Vacation':
                return '#6ED0F5';

            case 'Holiday':
                return '#1F3BC7';

            case 'Agent Removal':
                return '#C2C2C2';

            case 'New Hire':
                return '#C2C2C2';

            default:
                return '#7D7D7D';

        }
    }

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{ background: eventBackground() }}>
                        <br />
                        <h2 style={{ fontFamily: 'Lexend', fontWeight: 'bold', color: '#ffffff' }}>{props.eventData.extendedProps[8].value}</h2>
                        <h2 style={{ fontFamily: 'Lexend', fontWeight: 'bold', color: '#ffffff' }}>{props.eventData.title}</h2>
                        <br />
                    </div>
                </div>

                {(props.eventData.title === 'Agent Removal' || props.eventData.title === 'New Hire' || props.eventData.title === 'Vacation') && <><div className="row" >
                    <br /><br />
                </div>
                    <div className="row">
                        <div className="col-md-5 text-center">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ fontFamily: 'Lexend', color: 'rgb(255,255,255)', background: eventBackground() }}>
                                                <h6 style={{ fontWeight: 'bold' }}>AGENT NAME</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr />
                                        <tr />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: eventBackground() }}>{props.eventData.extendedProps[22].value}</h6>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5 text-center">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ fontFamily: 'Lexend', color: 'rgb(255,255,255)', background: eventBackground() }}>
                                                <h6 style={{ fontWeight: 'bold' }}>FUNCTION</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr />
                                        <tr />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: eventBackground() }}>{props.eventData.extendedProps.function}</h6>
                        </div>
                    </div></>}


                {(props.eventData.title !== 'Holiday' && props.eventData.title !== 'Agent Removal' && props.eventData.title !== 'New Hire' && props.eventData.title !== 'Vacation') && <>
                    <div className="row" >
                        <br /><br />
                    </div>
                    <div className="row">
                        <div className="col-md-5 text-center">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ fontFamily: 'Lexend', color: 'rgb(255,255,255)', background: eventBackground() }}>
                                                <h6 style={{ fontWeight: 'bold' }}>TIME</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr />
                                        <tr />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: eventBackground() }}>{props.eventData.extendedProps[9].value}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 text-center">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ fontFamily: 'Lexend', color: 'rgb(255,255,255)', background: eventBackground() }}>
                                                <h6 style={{ fontWeight: 'bold' }}>MODE</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr />
                                        <tr />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: eventBackground() }}>{props.eventData.extendedProps[10].value}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 text-center">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ fontFamily: 'Lexend', color: 'rgb(255,255,255)', background: eventBackground() }}>
                                                <h6 style={{ fontWeight: 'bold' }}>CATEGORY</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr />
                                        <tr />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: eventBackground() }}>{props.eventData.extendedProps[12].value}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 text-center">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ fontFamily: 'Lexend', color: 'rgb(255,255,255)', background: eventBackground() }}>
                                                <h6 style={{ fontWeight: 'bold' }}>ISTA STAFF</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr />
                                        <tr />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: eventBackground() }}>{props.eventData.extendedProps.ista_people}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 text-center">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ fontFamily: 'Lexend', color: 'rgb(255,255,255)', background: eventBackground() }}>
                                                <h6 style={{ fontWeight: 'bold' }}>AGENDA</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr />
                                        <tr />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: eventBackground() }}>{props.eventData.extendedProps[13].value}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 text-center">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ fontFamily: 'Lexend', color: 'rgb(255,255,255)', background: eventBackground() }}>
                                                <h6 style={{ fontWeight: 'bold' }}>URL</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr />
                                        <tr />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 style={{ fontFamily: 'Lexend', fontWeight: 'normal', color: eventBackground() }}>{props.eventData.extendedProps[11].value}</h6>
                        </div>
                    </div>
                </>
                }
            </div>
        </div >
    )
}

export default EventModal
