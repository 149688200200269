import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { parse } from 'fast-xml-parser';
import QuickBase from "quickbase-cors";

function Login() {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {

        var myHeaders = new Headers();
        myHeaders.append("QUICKBASE-ACTION", "API_Authenticate");
        myHeaders.append("Content-Type", "application/xml");
        myHeaders.append('Access-Control-Allow-Origin', 'http://localhost:3000');
        myHeaders.append('Access-Control-Allow-Credentials', 'true');

        var raw = "<qdbapi>\r\n   <username>" + data.Email + "</username>\r\n   <password>" + data.Password + "</password>\r\n   <hours>24</hours>\r\n</qdbapi>";
        console.log(raw);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        var quickbase_url = 'https://istasolutions.quickbase.com';
        fetch("https://cors.bridged.cc/"+quickbase_url + "/db/main?a=API_Authenticate", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                try {
                    var finalResult = result.match(/<ticket>(.*?)<\/ticket>/g).map(function (val) {
                        return val.replace(/<\/?ticket>/g, '');
                    })
                    return finalResult;
                } catch (error) {
                    return '';
                }
            }).then(result => {
                if (result.length > 0) {
                    localStorage.setItem("qb_user_auth_ticket", result);
                    var myDate = new Date();
                    myDate.setHours(myDate.getHours() + 24);
                    localStorage.setItem("qb_user_auth_ticket_expire", myDate.getTime());
                    window.location.reload();
                } else {
                    window.alert("Invalid Username or Password");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <div>
            <div>
                <div className="panel-heading"><br />
                    <center><img className="img-responsive" alt="ista solutions" src="https://istasolutions.com/wp-content/uploads/2018/05/logo-250-min.png" />
                    </center>
                    <br /><br /><br />
                    <center><h2 className="panel-title">Client Portal</h2><br /><br /><br /></center>
                </div>
                <div className="panel-body">
                    <form roleName="form" onSubmit={handleSubmit(onSubmit)}>
                        <fieldset>
                            <div className="form-group">
                                <input className="form-control" placeholder="Username" name="email" type="text" {...register("Email", { required: true, min: 1 })} autofocus />
                            </div>
                            <div className="form-group">
                                <input className="form-control" placeholder="Password" name="password" type="password" defaultValue="" {...register("Password", { required: true, min: 1 })} />
                            </div>
                            {/* Change this to a button or input when using this as a form */}
                            <button className="btn btn-lg btn-block" style={{ backgroundColor: "#00AEEF", color: "#FFFFFF" }}>Sign In</button>
                            <br /><br /><a href="https://login.quickbase.com/db/main?a=ForgotPassword&what=">I forgot my password</a>

                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
