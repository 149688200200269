import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
// The record is coming of clicked employee from MyTeam same Report in Quickbase

export default function CommentModal(props) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [message, setMessage] = useState(false);

    const onSubmit = data => {
        var myHeaders = new Headers();
        myHeaders.append("QB-Realm-Hostname", "istasolutions.quickbase.com");
        myHeaders.append("User-Agent", "FileService_Integration_V2.1");
        myHeaders.append('Authorization','QB-TICKET ' + localStorage.getItem("qb_user_auth_ticket"));
        myHeaders.append("Content-Type", "application/json");

        // var raw = '{"to": "brd7ic6nq","data": [{"6": {"value": ' + data.Subject + '},"7": {"value": ' + data.Function + '},"8": {"value": ' + data.Comments + '}}],"fieldsToReturn": [6,7,8]}';

        var raw = '{"to":"bq9kvvdqm","data":[{"6":{"value":"' + data.Subject + '"},"8":{"value":"' + data.Comments + '"},"14":{"value":"' + data.To + '"},"13":{"value":"' + data.From + '"}}],"fieldsToReturn":[6,8]}'
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        fetch("https://api.quickbase.com/v1/records", requestOptions)
            .then(response => response.text())
            .then(response => {
                setMessage(<h4>Thank you for reaching out! We will review your message and get back to you</h4>);
            }).catch(error => {
                console.log('error', error);
                setMessage(<h4>Comments submission failed</h4>);
            });
        console.log(errors);
    }

    return (
        <div className="container">
            <div className="row">

                <div className="col-md-12  d-flex align-items-center">
                    <h1 style={{ fontFamily: 'Lexend', fontWeight: 'bold', color: '#282587' }}>Send Us A Message</h1>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-12 d-flex">
                    {message ? (message) : (<form onSubmit={handleSubmit(onSubmit)} style={{width:'100%'}}>
                        <div class="form-group">
                            <label for="subject">Subject</label>
                            <input type="text" class="form-control" id="subject" placeholder="Subject" {...register("Subject", { required: true, min: 1 })} />
                        </div>
                        <div class="form-group">
                            <label for="from">From</label>
                            <input type="text" class="form-control" id="from" placeholder="From" {...register("From", { required: true, min: 1 })} />
                        </div>
                        <div class="form-group">
                            <label for="to">To</label>
                            <input type="text" class="form-control" id="to" placeholder="To" {...register("To", { required: true, min: 1 })} />
                        </div>
                        {/* <div class="form-group">
                            <label for="exampleFormControlSelect1">Function</label>
                            <select {...register("Function", { required: true })} class="form-control">
                                <option value="Vaccine Appointments">Vaccine Appointments</option>
                                <option value="Auth Dept">Auth Dept</option>
                                <option value="Covid Test Result">Covid Test Result</option>
                                <option value="Insurance Updates">Insurance Updates</option>
                                <option value="Medicaid">Medicaid</option>
                                <option value="Medical Records">Medical Records</option>
                                <option value="Pedia Vaccine">Pedia Vaccine</option>
                            </select>
                        </div> */}
                        <div class="form-group">
                        <label for="message">Message</label>
                            <textarea class="form-control" rows="5" id="message" placeholder="Message" {...register("Comments", { required: true, min: 1 })} />
                        </div>
                        <input type="submit" class="btn btn-primary" />
                    </form>)}
                </div>
            </div>
        </div>
    )
}
