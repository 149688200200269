import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './index.css';
import './fonts/Acelire Rosse.ttf';
import './fonts/Lexend-Bold.ttf';
import './fonts/Lexend-ExtraBold.ttf';
import './fonts/Lexend-Light.ttf';
import './fonts/Lexend-Medium.ttf';
import './fonts/Lexend-Regular.ttf';
import './fonts/Lexend-SemiBold.ttf';
import './fonts/Lexend-Thin.ttf';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
