import { PureComponent } from "react";

export class WeatherWidget extends PureComponent {

    render() {
        return (
            <div className={this.props.className || ""}>
              <a className="weatherwidget-io" href={this.props.link} data-icons="Climacons Animated" data-mode="Current" data-theme="pure" data-basecolor={this.props.basecolor}>{this.props.city_name} WEATHER</a>
                {!function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    // if (!d.getElementById(id)) {
                        js = d.createElement(s);
                        js.id = id;
                        js.src = 'https:weatherwidget.io/js/widget.min.js';
                        fjs.parentNode.insertBefore(js, fjs);
                    // }
                }
                    (document, 'script', 'weatherwidget-io-js')
                }
    
            </div>
        )
    }};